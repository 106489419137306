import { API_TOKEN_KEY, API_URL } from "./constant";
import { getLoginToken } from "./login_helper";

export async function getApplication(appid) {
    const res = await fetch(API_URL + "get_app.php?token=" + getLoginToken() + "&api_key=" + API_TOKEN_KEY + "&appid=" + appid);
    if (res.status !== 200) throw await res.json();
    return await res.json();
}

export async function getInventoryItems(department, platform) {
    if (platform === undefined) {
        const res = await fetch(API_URL + "get_app.php?token=" + getLoginToken() + "&api_key=" + API_TOKEN_KEY + "&department=" + department);
        if (res.status !== 200) throw await res.json();
        return await res.json();
    } else {
        const res = await fetch(API_URL + "get_app.php?token=" + getLoginToken() + "&api_key=" + API_TOKEN_KEY + "&department=" + department + "&platform=" + platform);
        if (res.status !== 200) throw await res.json();
        return await res.json();
    }
}

export async function getAllProject() {
    const res = await fetch(API_URL + "get_app.php?token=" + getLoginToken() + "&api_key=" + API_TOKEN_KEY);
    if (res.status !== 200) throw await res.json();
    return await res.json();
}

export async function getPermissionMeta() {
    const res = await fetch(API_URL + "get_app_and_user_list.php?token=" + getLoginToken() + "&api_key=" + API_TOKEN_KEY);
    if (res.status !== 200) throw await res.json();
    return await res.json();
}

export async function updatePermission(app, usr, auth) {
    const res = await fetch(API_URL + "authorize.php?token=" + getLoginToken() + "&api_key=" + API_TOKEN_KEY, {
        method: "POST",
        body: JSON.stringify({ appid: app, userid: usr, role: auth })
    });
    if (res.status !== 200) throw await res.json();
    return await res.json();
}


export async function newApplication(appname, image_link, description, category, sub_category, department, platform, appurl, source, source_link) {
    const res = await fetch(API_URL + "new_application.php?token=" + getLoginToken() + "&api_key=" + API_TOKEN_KEY, {
        method: "POST",
        body: JSON.stringify({ appname: appname, image_link: image_link, description: description, category: category, sub_category: sub_category, department: department, platform: platform, appurl: appurl, source: source, source_link: source_link })
    });
    if (res.status !== 200) throw await res.json();
    return await res.json();
}

import MainMenu from "../component/menu_frame";

function NewUser() {
    return (
        <MainMenu>
            Under Progress - NewUser
        </MainMenu>
    );
}

export default NewUser;

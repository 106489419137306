import { Alert, AlertIcon, Button, Container, FormControl, GridItem, Select, SimpleGrid } from "@chakra-ui/react";
import MainMenu from "../component/menu_frame";
import { getPermissionMeta, updatePermission } from "../methods/app_halper";
import { useState } from "react";

function UserPermission() {
    const [responseCode, setResponseCode] = useState("info");
    const [response, setResponse] = useState("");
    const [user, setUser] = useState(null);
    const [application, setApplication] = useState(null);

    if (user == null && application == null) {
        getPermissionMeta().then((res) => {
            if (res.code === 200) {
                console.log(res);
                setUser(res.users);
                setApplication(res.applications);
            } else {
                setUser([]);
                setApplication([]);
            }
        }).catch((err) => {
            console.log(err);
            setUser([]);
            setApplication([]);
        });
    }

    function handleSubmit(e) {
        e.preventDefault()
        const frm = document.forms.auth;
        updatePermission(frm.app.value, frm.person.value, frm.role.value).then((res) => {
            setResponse(res.message)
            setResponseCode(res.status)
        }).catch((err) => {
            setResponse(err)
            setResponseCode("error")
        });
    }

    return (
        <MainMenu>
            <Container maxW='xl' mb={10} mt={5}>

                {
                    response === "" ? "" :
                        <Alert status={responseCode}>
                            <AlertIcon />
                            {response}
                        </Alert>
                }

                <form name="auth">
                    <SimpleGrid columns={[1]} spacing='10px' gap={4} padding={5}>
                        <GridItem>
                            <FormControl>
                                <Select placeholder='Application Name' name="app">
                                    {application === null ? "" : application.map((itm, idx) => (
                                        <option value={itm.appid} key={idx}>{itm.appname}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <Select placeholder='Person' name="person">
                                    {user === null ? "" : user.map((itm, idx) => (
                                        <option value={itm.userid} key={idx}>{itm.username} - {itm.user_type}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <Select placeholder='Authorization' name="role">
                                    <option value='Read'>Read</option>
                                    <option value='Write'>Write</option>
                                </Select>
                            </FormControl>
                        </GridItem>
                    </SimpleGrid>
                    <Button w={'100%'} colorScheme="pink" onClick={handleSubmit} type='button'>Grant</Button>
                </form>
            </Container>
        </MainMenu>
    );
}

export default UserPermission;

import { useNavigate } from "react-router-dom";
import { saveSession, sixpetalLogin } from "../methods/login_helper";
import { Box, Button, Checkbox, Container, Divider, FormControl, FormLabel, Heading, HStack, Input, Stack, Text, } from '@chakra-ui/react'
import { useState } from "react";

function Login() {
    const navigator = useNavigate();

    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [error, setError] = useState('');


    const handleUserChange = (e) => setUser(e.target.value)
    const handlePassChange = (e) => setPass(e.target.value)

    function login(user, pass) {
        sixpetalLogin(user, pass).then((res) => {
            saveSession(res);
            navigator("/");
        }).catch((err) => {
            setError(err.message);
        })
    }

    return (
        <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
            <Stack spacing="8">
                <Stack spacing="6">
                    {/* <Logo /> */}
                    <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                        <Heading size={{ base: 'xs', md: 'sm' }}>Log in to your account</Heading>
                        {/* <Text color="fg.muted"> Don't have an account? <Link href="#">Sign up</Link></Text> */}
                        <Text color='tomato' textAlign="center">{error}</Text>
                    </Stack>
                </Stack>
                <Box py={{ base: '0', sm: '8' }} px={{ base: '4', sm: '10' }} bg={{ base: 'transparent', sm: 'bg.surface' }} boxShadow={{ base: 'none', sm: 'md' }} borderRadius={{ base: 'none', sm: 'xl' }} >
                    <Stack spacing="6">
                        <Stack spacing="5">
                            <FormControl>
                                <FormLabel htmlFor="username">Username</FormLabel>
                                <Input id="username" type="text" name="username" value={user} onChange={handleUserChange} />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="password">Password</FormLabel>
                                <Input id="password" type="password" name="password" value={pass} onChange={handlePassChange} />
                            </FormControl>
                        </Stack>
                        <HStack justify="space-between">
                            <Checkbox defaultChecked>Remember me</Checkbox>
                            <Button variant="text" size="sm">
                                Forgot password?
                            </Button>
                        </HStack>
                        <Stack spacing="6">
                            <Button onClick={(e) => { login(user, pass); }}>Sign in</Button>
                            <HStack>
                                <Divider />
                                <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                                    or continue with
                                </Text>
                                <Divider />
                            </HStack>
                            <Button onClick={() => { login(user, pass); }}>Sixpetal OAuth</Button>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Container>
    );
}

export default Login;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import NotFound from './routers/not_found';
import { ChakraProvider } from '@chakra-ui/react';
import Login from './routers/login';
import { getAuth, isLogin } from './methods/login_helper';
import { SPAX_ADMIN } from './methods/constant';
import Department from './routers/department';
import UserPermission from './routers/user_permission';
import NewApp from './routers/new_app';
import ApplicationsList from './routers/app_list';
import NewUser from './routers/new_user';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<App />} />
          <Route path="/login" element={<Login />} />
          <Route path='/departments' element={isLogin() && getAuth() === SPAX_ADMIN ? '' : <Navigate to="/login" />}>
            <Route path=":department/:category?" element={<Department />} />
          </Route>
          <Route path='/application' element={isLogin() && getAuth() === SPAX_ADMIN ? '' : <Navigate to="/login" />}>
            <Route path="show/:appid" element={<App />} />
            <Route path="new/:appid?" element={<NewApp />} />
            <Route path="applist" element={<ApplicationsList />} />
            <Route path="delete" element={<ApplicationsList />} />
          </Route>
          <Route path='/users' element={isLogin() && getAuth() === SPAX_ADMIN ? '' : <Navigate to="/login" />}>
            <Route path="new" element={<NewUser />} />
            <Route path="permission" element={<UserPermission />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

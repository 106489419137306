import { Alert, AlertIcon, Button, Container, FormControl, FormHelperText, FormLabel, GridItem, Heading, Input, SimpleGrid } from "@chakra-ui/react";
import MainMenu from "../component/menu_frame";
import { useState } from "react";
import { getApplication, newApplication } from "../methods/app_halper";
import { useParams } from "react-router-dom";


function NewApp() {
    const [responseCode, setResponseCode] = useState("info");
    const [defaults_values, setDefaultsValues] = useState({ appid: -1, appname: "", image_link: "", description: "", category: "", sub_category: "", department: "", platform: "", appurl: "", source: "", source_link: ""});
    const [response, setResponse] = useState("");
    const { appid } = useParams();

    if(appid && defaults_values.appid === -1){
        getApplication(appid).then((res) => {
            setDefaultsValues(res);
        }).catch((err) => {
            console.log(err);
            setDefaultsValues({ appid: 0, appname: "", image_link: "", description: "", category: "", sub_category: "", department: "", platform: "", appurl: "", source: "", source_link: ""});
        });
    }
    

    function nameChange(field) {
        // field.target.value = "test1";
    }

    const INPUTS = [
        { label: "Application Name", type: "text", name: "appname", default: defaults_values.appname, help: "Application name", error: "", onchange: nameChange },
        { label: "Image Link", type: "text", name: "image_link", default: defaults_values.image_link, help: "Application image URL", error: "", onchange: null },
        { label: "Description", type: "text", name: "description", default: defaults_values.description, help: "", error: "", onchange: null },
        { label: "Category", type: "text", name: "category", default: defaults_values.category, help: "Ex: Website | Application | Documentation | Code", error: "", onchange: null },
        { label: "Sub Category", type: "text", name: "sub_category", default: defaults_values.sub_category, help: "", error: "", onchange: null },
        { label: "Department", type: "text", name: "department", default: defaults_values.department, help: "Sales | Services | Reports | Code Labs | Support", error: "", onchange: null },
        { label: "Platform", type: "text", name: "platform", default: defaults_values.platform, help: "Web | Mobile | Desktop | Embedded | Learning | Database | Other", error: "", onchange: null },
        { label: "Application URL", type: "text", name: "appurl", default: defaults_values.appurl, help: "Link to the application", error: "", onchange: null },
        { label: "Source", type: "text", name: "source", default: defaults_values.source, help: "Ex SSD | Web | GitHub | GitLab", error: "", onchange: null },
        { label: "Source Link", type: "text", name: "source_link", default: defaults_values.source_link, help: "Source code link", error: "", onchange: null },
    ];

    function handleSubmit(e) {
        e.preventDefault()
        const frm = document.forms.app_form;
        newApplication(frm.appname.value, frm.image_link.value, frm.description.value, frm.category.value, frm.sub_category.value, frm.department.value, frm.platform.value, frm.appurl.value, frm.source.value, frm.source_link.value).then((res) => {
            setResponse(res.message)
            setResponseCode(res.status)
        }).catch((err) => {
            setResponse(err.message)
            setResponseCode(err.status)
        });
        frm.reset()
    }

    return (
        <MainMenu>
            <Container maxW='xxl' mb={10} mt={5}>
                {
                    response === "" ? "" :
                        <Alert status={responseCode}>
                            <AlertIcon />
                            {response}
                        </Alert>
                }
                <form name="app_form">
                    <Heading size={'md'} textAlign={'center'}>New Application</Heading>
                    <SimpleGrid columns={[1, 1, 2]} spacing='10px' gap={4} padding={5}>
                        {INPUTS.map((itm, idx) => (
                            <GridItem key={idx}>
                                <FormControl>
                                    <FormLabel>{itm.label}</FormLabel>
                                    <Input type={itm.type} defaultValue={itm.default} name={itm.name} onChange={itm.onchange} />
                                    <FormHelperText>{itm.help}</FormHelperText>
                                </FormControl>
                            </GridItem>
                        ))}
                    </SimpleGrid>
                    <Button w={'100%'} colorScheme="pink" onClick={handleSubmit}>Save</Button>
                </form>
            </Container>
        </MainMenu>
    );
}

export default NewApp;
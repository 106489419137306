import './css/App.css';
import MainMenu from './component/menu_frame';
import { Box, Heading, Text, VStack, Button, SimpleGrid, GridItem, StatGroup, Stat, StatLabel, StatNumber, StatHelpText } from '@chakra-ui/react';


function App() {
  return (
    <MainMenu>
      <Box textAlign="center" py={10} px={6}>
        <VStack spacing={3}>
          <Heading textAlign={"center"} color={"blue.500"}>Sixpetal App Dashboard</Heading>
          <Text textAlign={"center"} color={"blue.700"}>Web - Mobile - Learning</Text>
          {/* <Heading as="h1" size="2xl" color={"blue.500"}>
            Sixpetal App Dashboard
          </Heading>
          <Text fontSize="lg" color={"blue.700"}>
            Web - Mobile - Learning
          </Text> */}
          <Button colorScheme="teal" size="lg" mt={6}>
            Explore Applications
          </Button>
        </VStack>

        <StatGroup mt={10} mb={10}>
          <Stat>
            <StatLabel>Total Applications</StatLabel>
            <StatNumber>33</StatNumber>
            <StatHelpText>Updated daily</StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Users</StatLabel>
            <StatNumber>1,200</StatNumber>
            <StatHelpText>Last 24 hours</StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>App Downloads</StatLabel>
            <StatNumber>3,400</StatNumber>
            <StatHelpText>Last month</StatHelpText>
          </Stat>
        </StatGroup>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6} mt={10}>
          <GridItem w="100%" h="150" bg="blue.500" borderRadius="md">
            <Text color="white" p={4}>
              Web Application
            </Text>
          </GridItem>
          <GridItem w="100%" h="150" bg="green.500" borderRadius="md">
            <Text color="white" p={4}>
              Mobile Application
            </Text>
          </GridItem>
          <GridItem w="100%" h="150" bg="orange.500" borderRadius="md">
            <Text color="white" p={4}>
              API Services
            </Text>
          </GridItem>
        </SimpleGrid>
      </Box>
    </MainMenu>
  );
}

export default App;

import { Box, Flex, Text, IconButton, Button, Stack, Collapse, Icon, Popover, PopoverTrigger, PopoverContent, useColorModeValue, useBreakpointValue, useDisclosure, Avatar, Divider, StatLabel, StatNumber, StatHelpText, Stat } from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon, ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { List, ListIcon, ListItem } from '@chakra-ui/react'
import { Drawer, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton } from '@chakra-ui/react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AppsIcon from '@mui/icons-material/Apps';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { isLogin, sixpetalLogout } from "../methods/login_helper";
import 'animate.css';
import './menu_frame.css';
import { blue } from '@mui/material/colors';

export default function MainMenu(props) {
  const navigator = useNavigate();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure()
  const btnRef = useRef()
  const { isOpen, onToggle } = useDisclosure()

  function logout() {
    sixpetalLogout();
    navigator("/");
  }

  return (
    <>
      <Box position={"fixed"} width={'100%'} zIndex={999}>
        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
        <Flex bg={useColorModeValue('white', 'gray.800')} color={useColorModeValue('gray.600', 'white')} minH={'60px'} py={{ base: 2 }} px={{ base: 4 }} borderBottom={1} borderStyle={'solid'} borderColor={useColorModeValue('gray.200', 'gray.900')} align={'center'}>
          <Flex flex={{ base: 1, md: 'auto' }} ml={{ base: -2 }} display={{ base: 'flex', md: 'none' }}>
            <IconButton onClick={onToggle} icon={isOpen ? <CloseIcon className='animate__animated animate__rotateIn' w={3} h={3} /> : <HamburgerIcon w={5} h={5} />} variant={'ghost'} aria-label={'Toggle Navigation'} />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
            <Text textAlign={useBreakpointValue({ base: 'center', md: 'left' })} fontFamily={'heading'} color={useColorModeValue('gray.800', 'white')}>
              <Box as={'a'} href='/' color={"pink.500"} fontWeight={'bold'}>Sixpetal Developer Labs</Box>
            </Text>
            <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
              <DesktopNav />
            </Flex>
          </Flex>
          <Stack flex={{ base: 1, md: 0 }} justify={'flex-end'} direction={'row'} spacing={6}>
            {isLogin() ?
              <Button as={Button} rounded={'full'} variant={'link'} cursor={'pointer'} minW={0} onClick={onDrawerOpen}>
                <Avatar size={'sm'} src={'./user.png'} name='App Dashboard' />
              </Button>
              :
              <Button as={'a'} fontSize={'sm'} fontWeight={400} variant={'link'} href={'/login'}>
                Sign In
              </Button>
            }
            {/* <Button as={'a'} display={{ base: 'none', md: 'inline-flex' }} fontSize={'sm'} fontWeight={600} color={'white'} bg={'pink.400'} href={'#'} _hover={{ bg: 'pink.300', }}>
              Sign Up
            </Button> */}
          </Stack>
        </Flex>
      </Box>

      <Drawer isOpen={isDrawerOpen} placement='right' onClose={onDrawerClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent padding={2}>
          <DrawerCloseButton />
          <DrawerHeader color={blue[700]}>Menu</DrawerHeader>
          <List spacing={3}>
            <Box as={'a'} href='/application/new'>
              <ListItem className='menu_item'>
                <ListIcon as={AppsIcon} /> New Application
              </ListItem>
            </Box>
            <Box as={'a'} href='/application/applist'>
              <ListItem className='menu_item'>
                <ListIcon as={AppRegistrationIcon} /> Update
              </ListItem>
            </Box>
            <Box as={'a'} href='/application/delete'>
              <ListItem className='menu_item'>
                <ListIcon as={DeleteForeverIcon} /> Deactivate
              </ListItem>
            </Box>
            <Divider />
            <Box as={'a'} href='/users/permission'>
              <ListItem className='menu_item'>
                <ListIcon as={AdminPanelSettingsIcon} /> User Auth
              </ListItem>
            </Box>
            <Box as={'a'} href='/users/new'>
              <ListItem className='menu_item'>
                <ListIcon as={AccountCircleIcon} /> New User
              </ListItem>
            </Box>
            {
              isLogin() ?
                <Box as={'a'} href='#'>
                  <ListItem onClick={logout} className='menu_item'>
                    <ListIcon as={LogoutIcon} /> Logout
                  </ListItem>
                </Box>
                : ""
            }
          </List>
          <DrawerFooter fontSize={12} color={'gray'}>
            <Stat>
              <StatLabel>Version</StatLabel>
              <StatNumber>1.0.1</StatNumber>
              <StatHelpText>&copy; 2024 - All right reserved</StatHelpText>
            </Stat>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Box minHeight={'100vh'} zIndex={0}>
        <Box minH={'60px'}></Box>
        {props.children}
      </Box>
    </>
  )
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200')
  const linkHoverColor = useColorModeValue('gray.800', 'white')
  const popoverContentBgColor = useColorModeValue('white', 'gray.800')

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Box as={'a'} p={2} href={navItem.href ?? '#'} fontSize={'sm'} fontWeight={500} color={linkColor} _hover={{ textDecoration: 'none', color: linkHoverColor, }}>
                {navItem.label}
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent border={0} boxShadow={'xl'} bg={popoverContentBgColor} p={4} rounded={'xl'} minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  )
}

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Box as={'a'} href={href} role={'group'} display={'block'} p={2} rounded={'md'} _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text transition={'all .3s ease'} _groupHover={{ color: 'pink.400' }} fontWeight={500}> {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex transition={'all .3s ease'} transform={'translateX(-10px)'} opacity={0} _groupHover={{ opacity: '100%', transform: 'translateX(0)' }} justify={'flex-end'} align={'center'} flex={1}>
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  )
}

const MobileNav = () => {
  return (
    <Stack bg={useColorModeValue('white', 'gray.800')} p={4} display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  )
}

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Box py={2} as={'a'} href={href ?? '#'} alignItems="center" _hover={{ textDecoration: 'none', }}>
        <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children && (<Icon as={ChevronDownIcon} transition={'all .25s ease-in-out'} transform={isOpen ? 'rotate(180deg)' : ''} w={6} h={6} />)}
      </Box>
      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack mt={2} pl={4} borderLeft={1} borderStyle={'solid'} borderColor={useColorModeValue('gray.200', 'gray.700')} align={'start'}>
          {children &&
            children.map((child) => (
              <Box as={'a'} key={child.label} py={2} href={child.href}>
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  )
}

const NAV_ITEMS = [
  {
    label: 'Sales',
    children: [
      {
        label: 'Website',
        subLabel: 'Website that are delivered to client or hosted',
        href: '/departments/Sales/Web ',
      },
      {
        label: 'Application',
        subLabel: 'Android, iOS, Windows application delivered to client',
        href: '/departments/Sales/Mobile',
      },
      {
        label: 'All',
        subLabel: '',
        href: '/departments/Sales',
      }
    ],
  },
  {
    label: 'Services',
    children: [
      {
        label: 'Website',
        subLabel: 'Internal websites, APIs and product support urls.',
        href: '/departments/Services/Web',
      },
      {
        label: 'Application',
        subLabel: 'Mobile application and utility tools, including hybrid application.',
        href: '/departments/Services/Mobile',
      },
      {
        label: 'Desktop',
        subLabel: 'Desktop application, tools and software only designed for computer',
        href: '/departments/Services/Desktop',
      },
      {
        label: 'Learning',
        subLabel: 'Documenttaion, blogs and other study links',
        href: '/departments/Services/Learning',
      },
      {
        label: 'All',
        subLabel: '',
        href: '/departments/Services',
      }
    ],
  },
  {
    label: 'Reports',
    children: [
      {
        label: 'Statistics',
        subLabel: 'Secondry data genrated by application and uses reports',
        href: '/departments/Reports/Learning',
      },
      {
        label: 'Database',
        subLabel: 'Database and database related reports',
        href: '/departments/Reports/Database',
      },
      {
        label: 'All',
        subLabel: '',
        href: '/departments/Reports',
      },
    ],
  },
  {
    label: 'Code Labs',
    href: '/departments/Code Labs',
  },
  {
    label: 'Support',
    href: '/departments/Support',
  },
]

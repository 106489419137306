import { API_TOKEN_KEY, API_URL } from "./constant";

export async function sixpetalLogin(usr, pass) {
    const res = await fetch(API_URL + "login.php?api_key=" + API_TOKEN_KEY, {
        method: "POST",
        body: JSON.stringify({ user: usr, password: pass })
    });
    if (res.status !== 200) throw await res.json();
    return await res.json();
}

export function saveSession(session) {
    localStorage.setItem("spax_token", session["token"]);
    localStorage.setItem("spax_status", session["status"]);
    localStorage.setItem("spax_username", session["username"]);
    localStorage.setItem("spax_auth", session["user_type"]);
}


export function sixpetalLogout() {
    localStorage.removeItem("spax_token");
    localStorage.removeItem("spax_status");
    localStorage.removeItem("spax_username");
    localStorage.removeItem("spax_auth");
}

export function getAuth() {
    return localStorage.getItem("spax_auth");
}

export function isActive() {
    return localStorage.getItem("spax_status") === 'Active';
}

export function getLoginToken() {
    return localStorage.getItem("spax_token");
}

export function isLogin() {
    const token = localStorage.getItem("spax_token");
    return token !== null && token !== undefined && token !== '';
}

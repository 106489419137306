import { useState } from "react";
import MainMenu from "../component/menu_frame";
import { getAllProject } from "../methods/app_halper";
import { Box, Table, TableCaption, TableContainer, Tag, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

function ApplicationsList() {
    const [data, setData] = useState(null);

    if (data === null) {
        getAllProject().then((res) => {
            setData(res);
        }).catch((err) => {
            console.error(err);
            setData([])
        });
    }
    return (
        <MainMenu>
            <TableContainer mt={5}>
                <Table variant='striped' size='sm'>
                    <TableCaption>Applications</TableCaption>
                    <Thead>
                        <Tr>
                            <Th>Application</Th>
                            <Th>Description</Th>
                            <Th>Platform</Th>
                            <Th>Category</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data === null ? <Tr><Td colSpan={5}>Loading</Td></Tr> :
                            data.map((item, idx) => (
                                <Tr key={idx}>
                                    <Td>#{item.appid} - {item.appname}</Td>
                                    <Td>{item.description.substring(0, 30) + "..."}</Td>
                                    <Td>{item.department} <Tag colorScheme="green"><small>{item.platform}</small></Tag></Td>
                                    <Td>{item.category} <Tag colorScheme="green"><small>{item.sub_category}</small></Tag></Td>
                                    <Td><Box as={'a'} href={'/application/new/' + item.appid} color={'blue.500'}>Edit</Box> | <Box as={'a'} href={'#'} color={'blue.500'}>Deactivate</Box></Td>
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </MainMenu>
    );
}

export default ApplicationsList;

import "../css/not_found.css";

function NotFound() {
    return (
        <div className="not-found">
            <img src="/hex.svg" alt="Webpage not found" />
            <div className="message-box">
                <h1>404</h1>
                <p>Page not found</p>
                <div className="buttons-con">
                    <div className="action-link-wrap">
                        <a href="/" className="link-button">Go to Home Page</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotFound;

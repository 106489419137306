import { useParams } from "react-router-dom";
import MainMenu from "../component/menu_frame";
import { getInventoryItems } from "../methods/app_halper";
import { Avatar, Box, Button, Card, CardBody, CardFooter, CardHeader, Divider, Flex, GridItem, Heading, IconButton, Image, Link, Menu, MenuButton, MenuItem, MenuList, Modal, ModalContent, ModalOverlay, SimpleGrid, SkeletonCircle, SkeletonText, Tag, Text } from "@chakra-ui/react";
import { useState } from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QRCode from "react-qr-code";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import MoreVertIcon from '@mui/icons-material/MoreVert';

function Department() {
    const { department, category } = useParams();
    const [data, setData] = useState(null);
    const [project, setProject] = useState(null);

    if (data === null) {
        getInventoryItems(department, category).then((res) => {
            setData(res);
        }).catch((err) => {
            console.error(err);
            setData([])
        });
    }

    return (
        <MainMenu>
            <Modal isOpen={project !== null} onClose={() => { setProject(null); }}>
                <ModalOverlay />
                <ModalContent>
                    {project === null ? "" :
                        <Card height={'100%'}>
                            {project.image_link === null ? "" : <Image objectFit='cover' src={project.image_link} alt={project.appname} borderRadius='lg' maxH={128} />}
                            <CardHeader>
                                <Flex spacing='4'>
                                    <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                                        <Avatar name={project.appname} />
                                        <Box>
                                            <Heading size='sm'>{project.appname}</Heading>
                                            <Text>{project.category} {project.sub_category === null ? "" : <Tag><small>{project.sub_category}</small></Tag>}</Text>
                                        </Box>
                                    </Flex>
                                    <Menu>
                                        <MenuButton variant='ghost' colorScheme='gray' as={IconButton}>
                                            <IconButton variant='ghost' colorScheme='gray' icon={<MoreVertIcon />} />
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem>Edit</MenuItem>
                                            <MenuItem>Permission</MenuItem>
                                            <Divider />
                                            <MenuItem>Delete</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Flex>
                            </CardHeader>
                            <CardBody>
                                <Text fontSize='sm'>#{project.appid}</Text>
                                <Text fontSize='sm'>Department: {project.department} <Tag colorScheme={"orange"}><small>{project.platform}</small></Tag></Text>
                                <Text fontSize='sm'>Source: <Link isExternal href={project.source_link}>{project.source}</Link></Text>
                                <Text fontSize='sm'>Created: {project.created_on}</Text>
                                <Text fontSize='sm'>Last Update: {project.updated_on}</Text>
                                <Divider />
                                <Text>{project.description}</Text>
                            </CardBody>
                            <Divider />
                            <CardFooter justify='space-between' flexWrap='wrap' >
                                <Button flex='1' variant='ghost'><Link isExternal href={project.appurl}>Open <ExternalLinkIcon mx='2px' /></Link></Button>
                                {project.appurl === null ? "" :
                                    <Menu>
                                        <MenuButton flex='1' variant='ghost' as={Button} leftIcon={<QrCodeScannerIcon />}>
                                            Show QR
                                        </MenuButton>
                                        <MenuList style={{ width: "100%", textAlign: "center" }}>
                                            <MenuItem><QRCode size={200} style={{ height: "auto", maxWidth: "100%" }} value={project.appurl} /></MenuItem>
                                        </MenuList>
                                    </Menu>
                                }
                            </CardFooter>
                        </Card>
                    }
                </ModalContent>
            </Modal>

            {data === null ?
                <Box padding='6' boxShadow='sm' bg='white'>
                    <SkeletonCircle size='10' />
                    <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                </Box>
                :
                <SimpleGrid columns={[1, 2, 3, 4]} spacing='10px' gap={4} padding={5}>
                    {
                        data.map((item, idx) => (
                            <GridItem key={idx}>
                                <Card height={'100%'}>
                                    <CardHeader>
                                        <Flex spacing='4'>
                                            <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                                                <Avatar name={item.appname} />
                                                <Box>
                                                    <Heading size='sm'>{item.appname}</Heading>
                                                    <Text>{item.category} {item.sub_category === null ? "" : <Tag><small>{item.sub_category}</small></Tag>}</Text>
                                                </Box>
                                            </Flex>
                                            <IconButton variant='ghost' colorScheme='gray' icon={<OpenInNewIcon />} onClick={() => { setProject(item) }} />
                                        </Flex>
                                    </CardHeader>
                                    <CardBody>
                                        <Link isExternal href={item.appurl}>Open <ExternalLinkIcon mx='2px' /></Link>
                                        <Text>{item.description.substring(0, 80) + "..."}</Text>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        ))
                    }
                </SimpleGrid>
            }
        </MainMenu>
    );
}

export default Department
